import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPhone.module.css';

const IconPhone = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.457 1.54036L7.91536 5.08203M12.1654 4.3737L12.1654 1.45812C12.1654 1.11234 11.8851 0.832031 11.5393 0.832031H8.6237M6.20192 7.50381C5.42424 6.72614 4.81017 5.84681 4.35972 4.9086C4.32097 4.8279 4.3016 4.78755 4.28671 4.73649C4.23382 4.55504 4.27181 4.33223 4.38184 4.17857C4.41281 4.13532 4.4498 4.09833 4.52378 4.02435C4.75005 3.79808 4.86319 3.68494 4.93715 3.57118C5.2161 3.14215 5.2161 2.58905 4.93716 2.16002C4.86319 2.04626 4.75005 1.93312 4.52378 1.70685L4.39766 1.58073C4.05371 1.23678 3.88173 1.0648 3.69703 0.971377C3.3297 0.785582 2.89589 0.785582 2.52856 0.971378C2.34386 1.0648 2.17188 1.23678 1.82792 1.58073L1.7259 1.68276C1.38312 2.02553 1.21173 2.19692 1.08084 2.42994C0.93559 2.6885 0.831154 3.09009 0.832037 3.38666C0.832832 3.65392 0.884676 3.83658 0.988364 4.2019C1.54559 6.16515 2.59697 8.0177 4.1425 9.56323C5.68803 11.1088 7.54058 12.1601 9.50383 12.7174C9.86915 12.8211 10.0518 12.8729 10.3191 12.8737C10.6156 12.8746 11.0172 12.7701 11.2758 12.6249C11.5088 12.494 11.6802 12.3226 12.023 11.9798L12.125 11.8778C12.469 11.5338 12.6409 11.3619 12.7344 11.1772C12.9201 10.8098 12.9201 10.376 12.7344 10.0087C12.6409 9.824 12.469 9.65202 12.125 9.30807L11.9989 9.18195C11.7726 8.95568 11.6595 8.84254 11.5457 8.76857C11.1167 8.48963 10.5636 8.48963 10.1345 8.76857C10.0208 8.84254 9.90765 8.95568 9.68138 9.18194C9.6074 9.25593 9.5704 9.29292 9.52716 9.32388C9.3735 9.43392 9.15069 9.47191 8.96924 9.41902C8.91818 9.40413 8.87783 9.38476 8.79713 9.34601C7.85892 8.89556 6.97959 8.28149 6.20192 7.50381Z"
        stroke="#5C5C5C"
        stroke-width="1.41667"
        stroke-linecap="round"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconPhone.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPhone.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPhone;
