import React from 'react';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import { Avatar, ReviewRating, UserDisplayName } from '../../components';

import css from './Reviews.module.css';

const Review = props => {
  const { review, intl, userTypes } = props;
  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });
  const authorUserType = review.author.attributes.profile.publicData.userType;
  const userType = !!authorUserType && userTypes?.find(ut => ut.userType === authorUserType)?.label;
  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} />
      <div className={css.reviewWrapper}>
        <div className={css.header}>
          <div className={css.user}>
            <UserDisplayName user={review.author} intl={intl} />
            {!!userType ? <div className={css.userType}>{userType}</div> : null}
          </div>
          <div className={css.reviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.reviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </div>
        </div>
        <p className={css.reviewContent}>{review.attributes.content}</p>
        <p className={css.reviewInfo}>{dateString}</p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, userTypes } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews.map(r => {
        return (
          <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
            <Review review={r} intl={intl} userTypes={userTypes} />
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
