import React, { useEffect } from 'react';
import classNames from 'classnames';

import css from './AdSenseAd.module.css';

const AdSenseAd = ({ rootClassName, className, slot, width, height, config }) => {
  useEffect(() => {
    try {
      if (window && typeof window !== 'undefined') {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (e) {
      console.error('AdSense error:', e);
    }
  }, []);

  const classes = classNames(rootClassName || css.root, className);
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
  const mobileStyles = { display: 'block', width: '100%', height: height, maxHeight: height };
  const desktopStyles = { display: 'block', width, height, maxHeight: height };
  const styles = isMobile ? mobileStyles : desktopStyles;
  const adFormat = slot === '6209025635' ? 'horizontal' : 'rectangle, horizontal';
  return (
    <>
      <div className={classes} style={styles}>
        <ins
          className="adsbygoogle"
          style={styles}
          data-ad-client={config.adSensePublisherId}
          data-ad-slot={slot}
          data-ad-format={adFormat}
          // data-full-width-responsive={isMobile ? "true" : "false"}
          data-full-width-responsive={'false'}
        ></ins>
      </div>
    </>
  );
};

export default AdSenseAd;
